.modal-redirect-provider {
    width: 636px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .title-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        .title-content {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
            text-align: center;
        }
        span {
            color: orange;
            font-weight: bold;
            font-size: 20px;
            margin-left: 10%;
            margin-right: 10%;
            text-align: center;
        }
    }
    .buttons-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        span {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
   
}