@import '../../../components/app-scss/base/variables';

.vehicles_options {

    .MuiFormControlLabel-root {
        width: 250px !important;
    }
    .MuiTypography-body1 {
        font-size: 0.9rem;
        font-family: $ff-rob;
        line-height: 1.5;
    }
}
.location_date_transfer_section {
    display: flex;
    flex-direction: column;

    .location_date_transfer_part {
        display: flex;
        justify-content: space-between;

        .MuiInputBase-input {
            text-indent: 15px;
            margin-top: 8px;
            width:350px
        }
    }
}

.multi {

    &.passengers {

        .passenger {

            &:first-child {

                hr {
                    display: none;
                }
            }
        }
    }
}

.fg-passenger-trash-col {
    position: absolute;
    z-index: 2;
    right: -70px;
    bottom: 26px;
}
