.menu > button {
    color: #fff;
}

.list > button {
    color: rgba(0, 0, 0, 0.87);
}

.tab-section {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    & > button {
        margin-right: 17px;
        font-size: 13px;
        font-weight: bold;
        padding: 0 12px 4px 12px;
        margin-bottom: -7px;
        cursor: pointer;

        &.active {
            border-bottom: solid 3.5px orange;
        }

        &:hover:not(.active) {
            border-bottom: solid 3.5px rgba(255, 166, 0, 0.479);
        }
    }
}
