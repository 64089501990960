/*
   Main box
   ========================================================================== */

%box-shadow {
    box-shadow: 4px 4px 15px rgba(#000, 0.09);
}

.main-box {
    margin-bottom: 1.5rem;
    &.no-bg {
        .main-box-content {
            background-color: transparent;
            box-shadow: none $imp;
        }
    }
}

.main-box-title {
    padding: 10px 20px 10px 40px;
    background-color: #2f2f2f;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-transform: uppercase;
    font-weight: 700;

    &.without-action {
        padding: 19px 20px 17px 40px !important;
    }
}

.main-box-content {
    padding: 1.5rem;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @extend %box-shadow;
}

.box-shadow {
    background-color: #fff;
    @extend %box-shadow;
}

.box-radius {
    border-radius: 5px;
}

/*
   Media icon boxes
   ========================================================================== */

.media-icon-box {
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 6px 0 rgba(#000, 0.16);
    border-radius: 6px;
    &.mic-sm {
        .mib-media-left {
            min-height: 88px;
        }
        .mib-round {
            svg {
                width: 44%;
            }
        }
    }
}

.mib-media-left {
    min-width: 104px;
    min-height: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    //@include gradient-y(#1482EF,#0065C9);
    border-radius: 6px;
}

.mib-round {
    @include size(62px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 12px 20px 0 rgba(#000, 0.1);
}

.mib-body {
    padding: 1rem;
    span {
        padding-right: 0;
        font-size: rem(18px);
        font-weight: 500;
        line-height: 1;
    }
    strong {
        font-size: rem(20px);
        line-height: 1;
    }
}

.mibb-content {
    align-items: center;
}

.user-card {
    min-width: 356px;
    padding: 1rem;
    color: #fff;
    background-color: $dark;
    border-radius: 6px;
}

.uc-title {
    margin-bottom: 1.2rem;
    @include font-size(rem(24px));
    font-weight: 500;
}

.uc-media {
    .media-body {
        font-size: rem(12px);
        font-family: $ff-pop;
    }
}

.uc-avatar {
    @include size(74px);
    padding: 4px;
    background-color: #fff;
    &,
    img {
        border-radius: 50%;
    }
}

.uc-meta {
    margin-top: 1.2rem;
    span {
        display: block;
        color: rgba(#fff, 0.46);
        font-size: rem(12px);
        font-weight: 300;
    }
    strong {
        text-transform: uppercase;
        font-size: rem(15px);
        font-weight: 300;
    }
}

/*
   Responsive
   ========================================================================== */

@media (min-width: $screen-xl) and (max-width: $screen-xxl) {
    .mib-media-left {
        min-width: 80px;
    }
    .mib-body {
        padding: 0.5rem;
    }
    .media-icon-box-col {
        // flex: 0 0 50%;
        // max-width: 50%;
    }
    .mibb-content {
        justify-content: center;
        span {
            padding-right: 15px;
            margin-bottom: 0.25rem;
        }
        span,
        strong {
            display: block;
            width: 100%;
            text-align: center;
            margin-left: 0 $imp;
        }
    }
}

@media (min-width: 1800px) {
    .mib-body {
        span {
            font-size: rem(20px);
        }
        strong {
            font-size: rem(28px);
        }
    }
}

@media (max-width: $screen-xxl - 1) {
    .mibb-content {
        span,
        strong {
            font-size: 0.975rem;
        }
    }
    .mib-round {
        @include size(40px);
        svg {
            width: 35%;
        }
    }
    .mib-media-left {
        min-height: 80px;
    }
}

@media (max-width: $screen-lg) {
    .main-content,
    .main-box-content {
        padding: 1rem;
    }
}
