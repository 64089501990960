.validate-section {
    margin-left: 0px;
    .validate {
        text-decoration: none;
        .validate-btn {
            cursor: pointer;
            background-color: #a1e89b;
            border: none;
            display: flex;
            justify-content: space-between;
            padding: 0 25px;
            align-items: center;
            box-shadow: 0 3px 6px 0 rgba(#000, 0.16);
            transition: background-color 0.1s ease-out;
            span {
                font-family: Roboto;
                color:red;
                font-size: 15px;
                color: white;
                text-decoration: none;
             
            }
            img {
                height: 15px;
            }
            &:focus {
                border: none;
                outline: none;
            }
            &:hover {
                background-color: #5ed853;
            }
        }
    }
}
