.board {
    margin-bottom: 40px;
    h3{
        color: #666;
        font-size: 1rem;
        text-transform: uppercase;
        padding: 0 15px;
    }

    .MuiTableContainer-root {
        max-height: 400px;
    }
}

