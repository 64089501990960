@import "../../../components/app-scss/base/variables";
.client_logo_table {
    min-width: 0% !important;
}

.transfer-list {
    .main-box {
        width: 83vw;
        table {
            overflow-x: auto;
            .status_list {
                min-width: 215px !important;
            }
            tr.cancelled {
                .MuiTableCell-body {
                    color: #f36666;
                    text-decoration: line-through;
                }
            }
            .history_column {
                min-width: 90px !important;
            }
            .MuiTableCell-head {
                min-width: 130px;
            }
            .action_open {
                min-width: 200px;
            }
            .actions-table {
                top: -24px;
            }
            .passengers_infos {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        .refresh-transfers {
            padding-top: 35px;
            display: flex;
            justify-content: flex-end;
            .MuiButton-root {
                background-color: #e8b01b;
                &:hover {
                    background-color: #c99300;
                }
            }
        }
    }
}

.tag-list-transfer-provider {
    display: flex;
    justify-content: center;
}

.decision {
    .actions-table {
        right: -74px !important;
    }
}
.tenders-list {
    .actions-table {
        left: 10px;
        right: inherit;
    }
}

.container-add-transfers {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    div {
        display: flex;
        flex-direction: column;
        text-align: center;
        span {
            color: $light-grey;
            font-size: 24px;
            text-transform: uppercase;
        }
    }
    button {
        margin-top: 30px;
        max-width: 250px;
    }
}

.note_cell{
    position: relative;
}
