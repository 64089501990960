.link-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    height: 30px;
    background-color: #A2EFA2;
    font-weight: bold;
    border-radius: 15px;

    .title {
        flex-grow: 1;
        color: white;
        text-transform: uppercase;
        font-size: 12px;
    }

    .rounded {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border-radius: 14px;
        background-color: white;
    }
}