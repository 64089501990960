.multi-select-section {
    .multi-select {
        margin-top: 7px;
        box-shadow: none;
        border: solid 1px rgb(202, 202, 202);
        height: 45px;
        width: 215px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        text-indent: 7px;

        .dropdown-container {
            width: 100%;
            box-shadow: none;
            border: none;
        }

        .dropdown-heading {
            display: flex;
            justify-content: space-between;
        }

        .dropdown-heading-value {
            span {
                font-size: 13px;
                color: rgb(54, 54, 54);
            }
        }

        .dropdown-content {
            padding-bottom: 15px;
        }
    }
}