.content-modal {
    align-items: flex-start !important;
    flex-direction: column;
    justify-content: flex-start;
}

.delete-modale-name {
    color: #dc143c;
}

.title-content {
    padding: 25px 17px 17px;
}

.delete-modale-action {
    border: none;
    padding: 10px 20px;
    background-color: #dc143c;
    color: #fff;
    font-size: 1.25rem;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.2s ease-in;
    &:hover {
        background-color: #bd0f32;
    }
}
