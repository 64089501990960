.brands-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flexed-img {
    display: flex;
    flex-direction: row;

    label {
        font-weight: 700;
        font-size: 0.75rem;
    }
}


