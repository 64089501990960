
.ticket-reply-content {
    display: flex;
    align-items: start;
    justify-content: space-between;

    .form-reply-ticket {
        width: 60%;
    }

    .ticket-history {
        width: 40%;

        .head-title-ticket-reply {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .form-section .section-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .ticket-reply-row {
            span:first-child {
                font-weight: bold;
            }
            span:nth-child(2) {
                float:right;
            }
        }
    }

}
