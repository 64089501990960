//== Projects vars
$img-path: "/images/";
$font-path: "/fonts/";
$svg-path: "/svg/";
$fa-font-path: "/fonts/fontawesome/";

$imp: unquote(!important);

//== Breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1520px,
);

$screen-sm: map-get($grid-breakpoints, sm);
$screen-md: map-get($grid-breakpoints, md);
$screen-lg: map-get($grid-breakpoints, lg);
$screen-xl: map-get($grid-breakpoints, xl);
$screen-xxl: map-get($grid-breakpoints, xxl);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1520px,
);

//== Typography
$ff-rob: "Roboto", sans-serif;
$ff-fa: "Font Awesome 5 Pro";
$ff-pop: "Poppins", sans-serif;
$text-weights: (300, 400, 500, 700, 900);
$headings-font-weight: 700;
$headings-margin-bottom: 1rem;
$enable-responsive-font-sizes: true;
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.2rem;
$h4-font-size: 1rem;
$h5-font-size: 0.85rem;
$h6-font-size: 0.8rem;

//== Colors
$body-bg: #fff;
$body-color: #302d2e;
$dark: #2f2f2f;
$light-grey: #cecece;

//== Svg
//$svg-settings: svg-settings(21,21);

// Navbar
$navbar-dark-color: #fff;
