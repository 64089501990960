.modal {
    .MuiPaper-root {
        .header-modal {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $dark;
            color: #fff;
            padding: 16px;
            text-transform: uppercase;
            padding: 20px 40px !important;
            letter-spacing: 1px;
            h2 {
                margin-bottom: 0;
                font-size: 16px;
            }
            span {
                cursor: pointer;
            }
        }
        .content-modal {
            width: 100%;
            min-height: 300px;
            padding: 16px;
            flex-direction: initial;
        }
    }
}