.head-state {
    color: #A5A8B6;
}

.section-title-clickable {
    display:flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}