.maps {
    .MuiTabs-root {
        border-bottom: 1px solid #dbdbdb;
        min-height: 20px;
        .MuiTab-root {
            font-weight: 600;
            min-width: 0;
            padding: 0;
            margin-right: 45px;
            min-height: 20px;
            &.Mui-selected{
                border-bottom: 1px solid #2f2f2f;
            }
            &.Mui-disabled{
                font-weight: 500;
            }
        }

    }
    .map{
        margin-top: 10px;
    }
}
.detail{
    h2.title{
        font-size: 1rem;
        text-transform: capitalize;
        padding-bottom: 10px;
        border-bottom: 1px solid #dbdbdb;
        &:after{
            content: " :";
        }
    }
    .row{
        margin: 10px 0;
    }
    .mission{
        div[class*="col-"]{
            padding-left: 0;
        }
        .date{
            text-transform: capitalize;
            font-size: 1.2rem;
            font-weight: 800;
        }
        .time{
            font-size: 1.5rem;
            font-weight: 800;
        }
        .point{
            padding: 10px 45px;
            .MuiIcon-root{
                position: absolute;
                margin-left: -40px;
                margin-top: -4px;
            }
        }
    }
    .btn-icon.refresh{
        background-color: #e8b01b;
        &:hover {
            background-color: #c99300;
        }
    }
    .label{
        font-weight: 600;
        font-size: 0.8rem !important;
    }
    .position{
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 25px;
        .status{
            text-align: center;
            .box{
                background: #dbdbdb;
                color: #fff;
                font-size: 0.8rem;
                padding: 10px;
                border-radius: 10px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.active{
                    &.started{
                        background: #A1E89B;
                    }
                    &.in_place{
                        background: #107DE8;
                    }
                    &.pickup{
                        background: #FED128;
                    }
                    &.dropoff{
                        background: #00C6FB;
                    }
                }
            }
            .hour{
                margin-top: 7px;
                font-size: 0.8rem;
                font-weight: 600;

            }
        }
    }
}

