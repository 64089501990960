.MuiAppBar-colorPrimary {
    &#header {
        /*background-color: $dark;*/
    }
}

.appbar-user-actions {
    .aua-notifications {
        margin-right: 1rem;
    }
}
.appbar-user-dropdown {
    width: 260px;
    .MuiButton-label {
        color: #fff;
        font-size: 1rem;
        text-transform: none;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        .user-info-section {
            display: flex;
            align-items: center;
        }
    }

    .aud-caret {
        margin-left: 1rem;
    }
}

.aud-placeholder {
    @include size(30px $imp);
    margin-right: 0.75rem;

    img {
        border-radius: 50%;
    }
    svg {
        @include size(100% $imp);

        path {
            fill: #fff;
        }
    }
}
