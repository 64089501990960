body {
    color: #302d2e $imp;
}

hr {
    height: 1px;
    background-color: #dbdbdb;
    border: none;
    &.hr-gray {
        background-color: #707070;
    }
    &.hr-2 {
        height: 2px;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

@each $weight in $text-weights {
    .text-#{$weight} {
        font-weight: $weight $imp;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

.section-title {
    padding-bottom: 1.2rem;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    +.section-border {
        &:before {
            width: 54px;
        }
    }
}

.title-modal {
    padding-bottom: 1.2rem;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
    text-align: center;
}

.section-border {
    position: relative;
    margin-bottom: 1.5rem;
    &:after,
    &:before {
        content: "";
        display: block;
        height: 1px;
    }
    &:after {
        width: 100%;
        background-color: #dbdbdb;
    }
    &:before {
        width: 110px;
        background-color: $dark;
    }
}

.test {
    @include font-size(rem(26px));
}

label.fw-400,
.fw-400 {
    font-weight: 400 $imp;
}


/*
   Responsive
   ========================================================================== */

@media (min-width: $screen-lg) {
    .section-title {
        padding-left: 46px;
    }
}