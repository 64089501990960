.driver_note_popup {
    background-color: white;
    z-index: 1;
    position: absolute;
    right: 0;
    min-width: 250px;
    padding: 10px;
    box-shadow: 4px 5px 13px rgba(71, 69, 69, 0.473);
    .section {
        display: flex;
        margin-bottom: 0;
        justify-content: space-between;
        .info_part {
            margin: 0 4px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 12px;
            .label {
                font-size: 14px;
                font-weight: normal;
                padding-bottom: 7px;
                letter-spacing: 1px;
            }
        }
    }
}
