@mixin multiselect() {
    --rmsc-border: none;
    --rmsc-h: 30px;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    --rmsc-gray: #000000;
}

.multi-select {
    background-color: white;

    .dropdown-heading-value {
        padding-left: 10px;
    }

    input {
        padding-left: 10px;
    }

    .item-renderer {
        padding: 5px 10px;
    }

    .dropdown-content * {
        background-color: white;
    }

    &,
    .filter-std-row & {
        @include multiselect();
    }
}

// .filter {
//     margin-right: 0.75rem;
// }