.MuiTabs-flexContainer {
    justify-content: flex-start!important;
}

.MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
}

.form-tabs.MuiTabs-root {
    .Mui-disabled{
        display: none;
    }
}

.MuiPaper-elevation4 {
    border-bottom: 0!important;
    box-shadow: none !important;
}