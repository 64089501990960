.no_assigned_popup {
    width: 200px;
    background-color: white;
    z-index: 1;
    position: absolute;
    left: 60px;
    bottom: 1px;
    padding: 20px;
    box-shadow: 4px 5px 13px rgba(71, 69, 69, 0.473);
    .info-section {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .text {
        color: indianred;
        font-size: 15px;
        line-height: 17px;
        letter-spacing: 1px;
        font-weight: 500;
    }
}