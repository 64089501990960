.deposit-card {
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        .label {
            font-weight: bold;
        }
    }

    .preview-button {
        background-color: rgba(128, 128, 128, 0.13);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 38px;
        .MuiButton-label {
            color: black;
        }
        &:hover {
            background-color: rgba(128, 128, 128, 0.3);
        }
    }
    .pdf{
        .MuiButton-label{
            width: 22px;
        }
        .MuiCircularProgress-root{
            height: auto !important;

        }
    }
}
