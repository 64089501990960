.content-modal-unasign {
    padding: 50px;
    .MuiTableContainer-root {
        max-height: 410px;
    }
    .MuiButton-root {
        background-color: #e8b01b;
        &:hover {
            background-color: #d19a02;
        }
    }
    .restore-icon {
        transition: all 0.1s ease-in;
        &:hover {
            cursor: pointer;
            color: orange;
        }
    }
    .history_column {
        min-width: 100px;
    }
    .actions-table {
        right: -28px;
    }
}
