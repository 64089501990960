.return-action {
    padding: 10px 20px;
    background-color: #FFF;
    color: #2F2F2F;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    transition: background-color ease 200ms;
    text-decoration: none;
    cursor: pointer;
}

.return-action:hover {
    padding: 8px 18px;
    background-color: transparent;
    color: #FFF;
    font-size: 1rem;
    font-weight: bold;
    border: 2px solid #FFF;
    border-radius: 25px;
    transition: background-color ease 200ms;
}