.form-title-decorator {
    width: 100%;
    color: #2F2F2F;
    height: 1px;
    border: none;
}

.section-title{
    display: flex;
    flex-direction: row;
}