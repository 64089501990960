.incident-icon{
    cursor: pointer;
    margin-bottom : -10px;
    path{
        fill: #ffc107;
    }
}

.tooltip-incident{
    display: flex;
    flex-direction: column;
}