.modal-notif {
    position: absolute;
    right: 103px;
    width: 350px;
    margin-top: 65px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #80808047;
    z-index: 11;

    &-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200vh;
        z-index: 10;
    }

    .header-notif {
        background: #2f2f2f;
        display: flex;
        flex-direction: column;
        height: 75px;
        padding: 5px 20px 0px 20px;
        border-radius: 6px;
        justify-content: space-around;

        .title-section {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-weight: bold;
                font-size: 17px;
                letter-spacing: 1px;
            }
        }
    }

    .notifications {
        display: flex;
        flex-direction: column;
        background-color: white;
        color: #2f2f2f;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 200px;

        &::-webkit-scrollbar {
            display: none;
        }

        .notif {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 5px;
            border-bottom: 1px solid #e5e5e5;
            background-color: white;
            cursor: pointer;
            padding: 16px 25px;
            transition: 0.1s all ease-in;

            &:hover {
                background-color: rgba(163, 163, 163, 0.295);
            }

            .infos {
                display: flex;
                flex-direction: row;
                align-items: center;

                .logo {
                    margin-right: 18px;

                    .MuiIconButton-root {
                        padding: 0 !important;

                        .makeStyles-imageBackground-34 {
                            width: 45px !important;
                            height: 45px !important;
                            background-size: 45px !important;
                        }
                    }
                }

                .texts {
                    display: flex;
                    flex-direction: column;
                    max-width: 70%;
                    max-height: 80px;

                    .user {
                        font-weight: 500;
                        font-size: 13px;
                    }

                    .content {
                        font-size: 11px;
                        margin-top: 2px;
                        color: gray;
                    }
                }
            }

            .more {
                display: flex;
                flex-direction: column;
                align-items: center;

                .hours {
                    font-size: 13px;
                }
            }
        }

        .notif:last-child {
            border-bottom: 0px;
        }

        .active {
            background-color: rgba(47, 47, 47, 0.3);
            color: white;
        }
    }

    .footer {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        border-radius: 5px;
        border-top: 3px solid #bbbbbb38;
    }
}

.no-notifs {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: gray;
}