.reset-password {
    width: 16%;
}

.password-reset {

    .MuiButton-contained {

        &.btn-form {
            padding: .5rem .75rem !important;
            min-width: inherit;
            min-height: inherit;
            text-transform: none;
        }
        &.btn-icon .MuiIcon-root {
            font-size: 1rem;
            margin-left: .5rem;
        }
    }
    .validate {
        margin-left: 0 !important;
    }
}