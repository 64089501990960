.container-modal-back {
    display: flex;
    padding: 50px 50px;
    justify-content: center;
    .text-back {
        text-align: center;
        .title {
            font-weight: 600;
            font-size: 20px;
            color: red;
        }
        .content {
            font-weight: 300;
            font-size: 16px;
        }
    }
}
