.modal-preview-pdf {
    width: 100%;
    height: auto;
    display: flex;
    .bill-pdf {
        .content-pdf {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            iframe {
                width: 90%;
            }
        }
    }
}