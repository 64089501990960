.content-modal-export {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding: 80px 30px;

    .flex-stretch-row {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
    }

    .item-export {
        justify-content: center;
        padding-left: 9px;
    }
}
