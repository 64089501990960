.flex-row-estimate{
    display: flex;
    flex-direction: row;
    align-items: center;

    .little{
        width: 40%;
    }

    .big{

    }   height: 60%;
}

.penalities{
    font-weight: 600;
    display: block;
    .penality{
        margin-top: 10px;
        margin-left: 100px;
        .date{
            margin: 0 40px;
            font-weight: 400;
            font-size: 80%;
        }
        .amount{
            display: inline-block;
            text-align: center;
            width: 200px;
        }
        .pdf{
            background-color: rgba(128, 128, 128, 0.13);
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 38px;
            .MuiButton-label {
                color: black;
            }
            &:hover {
                background-color: rgba(128, 128, 128, 0.3);
            }
        }
    }

}