.recap-container {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 13px;
    .recap-content {
        width: 35%;
        padding-top: 20px;
        .line-recap:not(.ttc_total) {
            border-bottom: 1px solid rgba(128, 128, 128, 0.11);
        }
        .line-recap {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            .label-section {
                display: flex;
                width: 42%;
                justify-content: flex-start;
            }
            .label {
                font-weight: 600;
            }
            &.ttc_total .value {
                color: rgb(255, 0, 0);
            }
            .checkboxs-section {
                display: flex;
                .checkbox-section {
                    display: flex;
                }
            }
        }
    }
}