.section-actions-table {
    width: 50px;
    position: relative;
}

.beneficiary-actions-row .actions-table {
    //right: -48px !important;
    top: -16px;
}

.driver-actions-row .actions-table {
    //right: -30px !important;
}

.actions-table {
    position: absolute;
    display: flex;
    right: -10px;
    top: -18px;

    &.drivers {
        right: -10px !important;
    }
    .icon {
        width: 30px;
        height: 30px;
        border: 1px solid;
        margin: 0 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
        }
    }
    .delete {
        background-color: #f36666;
        border-color: #f36666;
    }
    .edit {
        border-color: #a2efa2;
        background-color: white;
    }
    .duplicate {
        border-color: orange;
        background-color: orange;
    }
    .cancel {
        border-color: #f36666;
        background-color: #f36666;
    }
    .reply{
        border-color: #a2efa2;
    }
}
