.container-plate{
    display: flex;
    flex-direction: column;
    align-items: center;

    .licence-plate{
        svg {
            width: 100px;
            height: auto;
        }
    
        .text-plate {
            position: absolute;
            margin-top: -27px;
            width: 100px;
            margin-left: 1px;
            text-align: center;
            letter-spacing: -0.6px;
            font-size: 15px;
            font-weight: bold;
        }
    }
}

