.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    span {
        letter-spacing: 6.5px;
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    background-color: #333433;
    color: white;
    padding: 10px 30px;

    .right-part {
        display: flex;
        flex-direction: row;

        .languages {
            padding: 0px 20px;

            span {
                margin: 0px 10px;
                cursor: pointer;
            }
        }

        .logout {
            margin-left: 20px;
            cursor: pointer;
        }
    }
}

.flag-container {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lang-flag {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.en {
    background-image: url("/images/menu/flags/english.svg");
}

.fr {
    background-image: url("/images/menu/flags/french.svg.png");
}

.MuiButton-textPrimary {
    .MuiButton-label {
        color: #2f2f2f !important;
    }
}
