.form {
    .input {
        border: none;
        border-left: 6px solid #e8b01b;
        height: 80px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 5px 5px 10px #33333315;
        text-indent: 25px;
        color: #707070;
        &.password {
            &::placeholder {
                text-transform: uppercase;
            }
        }
        &:focus {
            outline: none;
            box-shadow: 5px 5px 10px #e8b11b2f;
        }
    }
    .email-group {
        position: relative;
        label {
            text-transform: uppercase;
            color: #70707063;
            font-size: 14px;
            position: absolute;
            z-index: 2;
            left: 33px;
            top: 8px;
        }
    }
    [type="submit"] {
        background-color: transparent;
        border: none;
        font-size: 19px;
        font-weight: 500;
        color: #707070;
    }
    .error {
        color: red;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .submit-section {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        .submit {
            input {
                cursor: pointer;
                &:focus {
                    outline: none;
                }
            }
            width: 150px;
        }
    }
}
