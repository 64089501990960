.search-section {
    display: flex;
    align-items: center;
    .global-search {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;

        input {
            width: 90%;
            height: 2.5rem;
            padding: 10px;
            border: none;
            outline: none;
            font-weight: 700;
            color: #000000;
            border-radius: 5px;
        }

        input::placeholder {
            color: #000000;
        }

        img {
            position: relative;
            right: 10px;
            height: 24px;
            width: auto;
            cursor: pointer;
        }

        .input-container {
            box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .refresh-filter {
        display: flex;
        background-color: #2f2f2f;
        padding: 5px;
        cursor: pointer;
        border-radius: 6px;
        margin-bottom: 15px;
        margin-left: 12px;
        transition: 0.1s all ease-in;
        &:hover {
            background-color: #2f2f2fa1;
        }
    }
}
