.quote-list {
    .see-quote {
        min-width: 170px !important;
    }
    .btn-rounded {
        background-color: grey;
        &:hover {
            background-color: rgb(90, 90, 90);
        }
    }
}
