@import '../../../../components/app-scss/base/variables';

.sub_form_passenger {
    display: flex;
    flex-direction: column;

    .first_row {
        display: flex;
        align-items: center;
    }
    .second_row {
        display: flex;
        justify-content: flex-start;
    }
    .delete_row {
        cursor: pointer;
        color: indianred;
        position: relative;
        left: 0;
        top: 20px;
    }
}


@media (max-width: $screen-lg - 1) {

    .sub_form_passenger {

        .react-tel-input .form-control {
            width: 100% $imp;
        }
    }
}
