.submit-container {
    .submit-input {
        margin-bottom: 20px;
        position: relative;
        float: right;
        top: 55px;
        left: 30px;
        .loader {
            width: 100%;
            display: flex;
            justify-content: center;
            .MuiCircularProgress-colorPrimary {
                color: #ffffff;
            }
        }
    }
}