.modal-accept-provider {
    width: 636px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .title-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        .title-content {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
        }
        p {
            color: orange;
            span {
                color: black;
            }
        }
    }
    .buttons-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .buttons-content {
            display: flex;
            width: 80%;
            justify-content: space-between;
        }
    }
}
