.form-section {
    margin-bottom: 45px;
    .content-form-section {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .form-part-align-center{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}
.form-part-align-center{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}