.details-previewing {
    margin-top: 30px;
    font-size: 14px;
    .folder {
        display: flex;
        justify-content: space-between;
        .code-folder {
            font-weight: bold;
            font-size: 18px;
        }
        .recipient {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            font-weight: bold;
            .value {
                color: red;
            }
        }
    }
    .client {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 15px;
        margin-bottom: 30px;
        .addresses {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .name {
                font-weight: bold;
            }
        }
        .date {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            .label {
                font-weight: bold;
            }
        }
    }
}