.mgl4 {
    margin-left: 4rem;
}

.provider-profile {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
 
}

.phone-number-input {
    margin-right: 30px;
    margin-Top:-5px ;
}

.phone-number-label{
 font-weight: 700;
 font-size: 12px;
}

.input-file-url{
 margin-top: -5px;
}