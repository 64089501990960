.multi {
    display: flex;

    &-container {
        display: inline-block;
    }
    &-controls {
        display: inline-block;
        padding: 4px;
        vertical-align: bottom;
    }
    .add {
        cursor: pointer;
        color: #fed128;
        font-size: 3em;
    }
    .add-supplement {
        cursor: pointer;
        color: #fed128;
        font-size: 3em;
        margin-bottom: 15px;
    }
}

.extra {

    + .extra {
        padding-top: 1.85rem;
        border-top: 1px solid #ebebeb;
    }
    // span {
    //     display: inline-block;
    //     padding-top: 28px;
    //     margin-right: 20px;
    // }
}

.multi-controls {
    display: flex;
    align-items: flex-end;
    position: relative;

}
.billing-adress {
    width: 500px;
}

.num-folder {
    padding: 0px 10px;
    font-size: 12px;
    background-color: #FED128;
    line-height: 9px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: white;
    border-radius: 5px;
}