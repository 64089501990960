.conditions-of-use{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: scroll;

    .content{
        width: 98%;
        text-align: justify;
        max-height: 300px;

        h3{
            margin-top: 10px;
        }

        h4{
            margin-top: 10px;
        }
        
        h5{
            text-decoration: underline;
        }
    }

    
}