.content-modal-unasign {
    padding: 50px;
    .actions {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }
    .comment-unnasign {
        width: 100%;
        legend {
            margin-bottom: 13px;
        }
    }
}
