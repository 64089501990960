.MuiTabs-flexContainer {
    .Mui-selected {
        span {
            font-weight: 700;
        }
    }
}

.MuiSvgIcon-root.edit-action {
    height: 2rem;
    width: 2rem;
    padding: 4px;
    margin-left: 0.25rem;
    border-radius: 2px;
    background-color: #FFF;
    border: 1px solid #A1E89B;
    color: #a1e89b;
}

.MuiSvgIcon-root.delete-action {
    height: 2rem;
    width: 2rem;
    padding: 4px;
    border-radius: 2px;
    background-color: #F36666;
    color: #FFF;
}

.submit_icon {
    margin-left: 7rem;
    display: flex;
    align-items: center;
    padding: 0 0 0 12px;

    svg {
        height: 2rem;
        width: 2rem;
        padding: 4px;
        border-radius: 2px;
        background-color: #FFF;
        border: 1px solid #A1E89B;
        color: #a1e89b;
    }
}

.submit_icon:focus {
    border: none;
    outline: none;
}

.MuiSvgIcon-root.cancel-edit {
    color: #FFF;
    background-color: #F36666;
    padding: 1px 0;
    height: 2rem;
    width: 2rem;
    margin-left: 0.25rem;
}
