.app-aside-menu {
    height: 100%;
    overflow: auto;
}

.asm-list {
    padding: 0 $imp;
    width: 100%;
    .MuiCollapse-container {
        .MuiButtonBase-root {
            padding-left: 2rem;
        }
    }
    .MuiListItem-root {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .MuiListItemIcon-root {
        min-width: 30px;
    }
    .MuiSvgIcon-root {
        fill: #cecece;
    }
    .MuiTypography-body1 {
        text-transform: uppercase;
        font-size: rem(13px);
        font-weight: 700;
    }
}

.list-baseline {
    padding: 1rem;
    text-align: center;
    background-color: #fff;
    font-weight: normal;
    box-shadow: 0 3px 6px 0 rgba(#000, 0.03);
}

.app-drawer {
    //flex-shrink: 0;
    &,
    .MuiDrawer-paper {
        width: 240px;
    }
    .MuiDrawer-paperAnchorDockedLeft {
        box-shadow: 2px 4px 25px 0 rgba(#000, 0.1);
        border: 0 $imp;
    }
}
