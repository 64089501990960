.content-form-section {
    //width: 90%;
}

.first-row-submit-problem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .form-section {
        display: inline-block;
    }
    .form-section:first-child {
        width: 60%;
    }
    .form-section:nth-child(2) {
        width: 40%;
    }
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #e8b01b !important;
}

.rdw-editor-toolbar {
    margin-top: 20px;
    border: 1px solid #DBDBDB !important;
    border-radius: 10px 10px 0 0 !important;
    margin-bottom: 0 !important;
}

.rdw-editor-main {
    border: 1px solid #DBDBDB !important;
    border-radius: 0 0 10px 10px !important;
    min-height: 150px;
}

.file-selected{
    display: flex;
    flex-direction: row;
    margin-left: 20px;

    .icon-delete{
        margin-left: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: red;
        cursor: pointer;

        svg{
            margin-right:2px;
        }
    }
}




