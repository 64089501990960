.left-side-nav {
    display: flex;
    flex-direction: column;
    width: 220px;
    min-height: calc(100vh - 50px);
    border-right: 1px solid black;

    .span-text {
        text-align: center;
        margin: 5px 0px;
    }

    .navigation {
        display: flex;
        flex-direction: column;
        padding: 20px;
        flex-grow: 1;
        justify-content: space-between;

        div {
            display: flex;
            flex-direction: column;

            a {
                padding: 5px 10px;
                text-decoration: none;
                color: black;
                cursor: pointer;
            }
    
            .sub-item {
                padding-left: 20px;
            }
        }

       
    }
}

.side-nav-link {
    width: 100%;
    color: black;
    text-decoration: none;
}

.hello{
   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; 
}