.container-login-page {
    background-color: #f8f8f8;
    height: 100vh;
    font-family: "Avenir";
    display: flex;
    align-items: center;
    margin-left: 11%;
    .login-page {
        background-color: #ffffff;
        display: flex;
        max-width: 1440px;
        max-width: 77vw;
        height: 90vh;
        .container-img {
            height: 100%;
            img {
                height: 100%;
            }
        }
        .container-login {
            .container-text {
                color: #707070;
                padding-left: 8%;
                padding-top: 4%;
                transition: 0.1s all ease-in;
                .text {
                    font-weight: 100;
                    font-size: 16px;
                }
                &:hover {
                    color: #3a3a3a;
                }
            }

            .login {
                padding: 0 100px;
                margin-top: 25px;
                .form-container {
                    width: 75%;
                    .admin-logo-section {
                        display: flex;
                        justify-content: center;
                        .top-logo {
                            width: 772px;
                            height: 56px;
                        }
                        .logo {
                            width: 100%;
                        }
                    }
                    .form-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0 5px;
                        width: 100%;
                        .lorem {
                            margin-top: 12px;
                            margin-bottom: 30px;
                            span {
                                font-size: 17px;
                                color: #707070;
                                font-weight: 400;
                            }
                        }
                        .forgot_password {
                            color: #707070;
                            font-size: 14px;
                            text-decoration: none;
                            margin-left: 15px;
                            opacity: 0.3;
                            transition: 0.1s opacity ease-in;
                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }
    }
    .tabs-section {
        height: 100%;
        display: flex;
        align-items: center;
        .tabs-content {
            height: 75%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .tab {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30%;
                width: 65px;
                box-shadow: 1px 4px 3px #b1b1b147;
                transition: 0.2s all ease-in;
                &.provider {
                    background-color: #415164;
                }
                &.admin {
                    background-color: #ffffff;
                }
                &.client {
                    background-color: #e8b01b;
                }
                img {
                    height: 70%;
                }
                &:hover {
                    padding-left: 35px;
                    width: 100px;
                }
            }
        }
    }
}
