.MuiTabs-flexContainer {
    background-color: white;
    color: black;
}

.MuiTabs-indicator {
    background-color: transparent !important;
}

.MuiPaper-elevation1 {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.07) !important;
}
.MuiPaper-elevation4 {
    border-bottom: 1px solid #ccc;
    box-shadow: none !important;
}

.MuiTabPanel-root {
    padding-top: 10px;
}

.MuiTabs-flexContainer {
    justify-content: space-around;
}

.section-title {
    font-weight: 700 !important;
}

.form_box_vehicle {
    width: 45%;
}

.list_box_vehicle {
    width: 50%;
    padding-top: 24px;
}

.big_box {
    width: 55%;
}

.small_box {
    width: 35%;
    padding-top: 24px;
}

.header-margin {
    padding-left: 1rem;
}

.vehicle-details-submit {
    float: right;
    margin-left: auto;
    flex-direction: row;
    align-items: center;

    button {
        //box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.09);
        min-width: 125px !important;
        border-radius: 4px;
    }
}

.param-search {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    input {
        background-color: transparent;
        border: none;
        color: #FFF;
        font-size: 0.75rem;
        font-weight: 700;
        padding-top: 0;
        padding-bottom: 5px;
        width: 100px;
    }

    input:focus {
        outline: none;
    }

    button {
        background-color: transparent;
        border: none;
        color: #FFF;
    }

    button:focus {
        outline: none;
    }
}
