.item-type {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 70px;
    }
}

.item-brand {
    display: flex;
    flex-direction: column;
    align-items: center;
    .MuiIconButton-label {
        width: 42px;
        height: 42px;
        background-size: 42px;
    }
}
