.quote-refused{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 4px solid #FF6106;
    padding: 10px;
    margin-top: 20px;

    .field-refused{
        margin: 0px 20px;
    }
}

.header-previouwing {
    margin-top: 10px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding: 15px 50px;
    margin-bottom: 10px;
    font-size: 13px;
    background-color: rgb(47, 47, 47);
}
