.modal-payment {
    height: 545px;
    overflow: hidden;
    .title-content {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        padding-bottom: 10px;
        padding-left: 20px;
        .code {
            color: red;
        }
    }
    .diviseur-payment {
        margin-top: 0.5rem;
        margin-bottom: 2rem;
    }
    .form-react-payment {
        height: 90%;
    }
    .form-part {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 50px;
        &.left {
            height: 80%;
        }
        &.right {
            height: 50%;
        }
    }
    .label-switch {
        margin-top: 5px;
        font-size: 13px;
    }
    .buttons-section {
        width: 100%;
        display: flex;
        margin-right: 65px;
    }
}
