.transfer_amount_card {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 3px;
    border-radius: 5px;
    border-top: 1px solid rgba(128, 128, 128, 0.692);
    & > * {
        padding: 5px 15px;
    }
    .img {
        .MuiButtonBase-root {
            padding-bottom: 0;
        }
    }
    .close_item {
        cursor: pointer;
        .close_icon {
            color: red;
        }
    }
}
