.row_pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    span {
        font-size: 12px;
        margin-left: 30px;
        margin-top: 15px;
    }
    .navigation_pages {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        .action {
            margin-left: 10px;
            margin-right: 10px;
            cursor: pointer;
        }

        .other_page {
            cursor: pointer;
            margin-left: 3px;
            margin-right: 3px;
        }

        .current_page {
            font-weight: bold;
            margin-left: 3px;
            margin-right: 3px;
        }
    }
}
