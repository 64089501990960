.mgl4 {
    margin-left: 4rem;
}

.provider-form-part {
    display: flex;
    align-items: flex-start;
}


.fg-phone-input {
    position: relative;
    padding-top: 1rem;

    .MuiFormLabel-root {
        position: absolute;
        z-index: 1;
        top: -6px;
        left: 0;
        font-size: .8rem !important;
    }
    .react-tel-input {
        margin-top: 0;

        input {
            height: 40px;
            width: 100% !important;
        }
    }
}