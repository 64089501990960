.credit-container{
    text-align: right;
    margin-bottom: 15px;

    span{
        margin-right: 10px;
    }
}
.modal-credit{
    input{
        margin-top: 10px;
        padding: 10px 8px;
        border: 1px solid #dbdbdb !important;
        border-radius: 6px;
    }
}