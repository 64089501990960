.no-pad {
    padding-top: 0;
}

.email-field {
    width: 300px;
}

.fname-field {
    width: 300px;
}

.lname-field {
    width: 300px;
}

.admin-form {
    margin-bottom: 2rem;
}

.admin-form-left {
    width: 350px;
}

.admin-form-right {
    margin-left: 2rem;
}

.admin-submit {
    margin-top: 1rem;
}
