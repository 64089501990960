.grant{
    padding: 10px;

    .delete{
        padding-top: 15px;
        margin-left: 30px;
    }
    .field-form{
        min-width: 200px;
    }
}


.grant-add-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 0 10px;

    .grant-submit{
        margin-left: 30px;
    }
}

