@import './src/components/app-scss/base/variables';

.mgl4 {
    margin-left: 4px;
}

.provider-type {

    .MuiTypography-body1 {
        font-weight: 200;
        padding-top: 0;
    }
}

.provider-mail {

    input {
        min-width: 250px;
    }
}

.form-react-provider {

    .form-section:first-child {
        margin-bottom: 0 !important;
    }
}

.provider-street {

    input {
        min-width: 270px;
    }
}

.test {
    white-space: pre-line;
}

.provider-misc {

    input {
        min-width: 120px;
    }
}
.provider-post-code {

    input {
        min-width: 100px;
    }
}

.provider-city {
    // margin-left: 4rem;

    input {
        min-width: 200px;
    }
}

.provider-country {
    margin-top: 25px;
    //margin-left: 2rem;

    input {
        min-width: 150px;
    }
}

.provider-tva-number {

    input {
        min-width: 200px;
    }
}

.provider-is-tender {

    &.form-group {
        min-width: 140px;
    }
}

.provider-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.checkboxs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .checkboxs-section {
            display: flex;
            flex-direction: row;
            .MuiCheckbox-root {
                padding: 0 !important;
            }
            .checkbox-container:first-child {
                .MuiCheckbox-root {
                    padding-left: 0 !important;
                }
            }
        }
    }
}

/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;
    width: 75px;
    height: 28px;
}

/* Hide default HTML checkbox */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #a1e89b;
}

input:focus + .slider {
    box-shadow: 0 0 1px #a1e89b;
}

input:checked + .slider:before {
    -webkit-transform: translateX(46px);
    -ms-transform: translateX(46px);
    transform: translateX(46px);
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.MuiFormControlLabel-root {
    + .MuiFormControlLabel-root {
        .MuiCheckbox-root {
            padding-top: 9px !important;
            padding-bottom: 9px !important;
        }
    }
}

.checkboxs-section {
    label {
        span {
            padding: 5px !important;
        }
    }
}
.licence-input{
    margin-top: 35px;
}
.btn-redirect {
    height: 50px;
    //margin-left:60px !important;
    margin-top:15px !important;
    border-radius: 30px;
    padding-top: 15px;
    white-space:nowrap;
    padding: 10px !important;
}

@media (min-width: $screen-lg) {

    .btn-redirect {
        margin-left: 40px $imp;
    }
}

