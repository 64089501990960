.message-history {
    
    &:last-child {

        .mi-col {

            &:after {
                content: none;
            }
        }
        hr {
            display: none;
        }
    }
}
.mi-col {
    position: relative;

    &:after {
        content: '';
        @include size(0,100%);
        border-left: 2px dashed #707070;
        position: absolute;
        left: 12px;
        top: 0;
    }
    .checkbox {
        position: relative;
        z-index: 1;
    }
    .checkbox-bg {
        display: block;
        @include size(24px,30px);
        background-color: #fff;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 5px;
    }
}
.mi-user {
    padding-top: 3px;
    font-size: rem(14px);
}
.mi-title {
    margin: 5px 10px 5px 0;
    font-size: rem(18px);
    font-weight: 700;
    color: rgba(#2F2F2F,.46);
}
.mi-content {
    margin: 10px 0;
}