.subform-options {
    .field-form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .decorator {
        padding-top: 22px;
        padding-left: 5px;
    }
    .add-option {
        color: orange;
        margin-top: 11px;
        svg {
            font-size: 40px;
        }
    }
}