.indicator {
  display: flex;
  flex-direction: row;
  width: 250px;
  height: 60px;
  border: 1px solid black;
  margin-right: 30px;

  .indicator-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
  }

  .indicator-round {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: white;
  }

  .indicator-content {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;

    .total {
      font-weight: bold;
    }
  }
}