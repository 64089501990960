.sub_form_point {
    display: flex;
    flex-direction: column;
    .first_row {
        display: flex;
        align-items: center;
    }
    .second_row {
        display: flex;
        justify-content: flex-start;
    }
    .delete_row {
        cursor: pointer;
        color: indianred;
    }
}
