.vehicle_color_ext{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
        display: inline-block;
        margin-top: 3px;
    }
}