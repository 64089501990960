.value_plus {
    margin-left: 10px;
    font-weight: normal;
}

.form-section-preview {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 0;
    .checkboxs-section {
        display: flex;
        .checkbox-section {
            display: flex;
            align-items: center;
            margin: 0 5px;
            .PrivateSwitchBase-root-15 {
                padding: 0;
            }
        }
    }
    .input {
        margin-top: 30px;
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input {
            width: 50%;
            border: none;
            border-bottom: 1px solid #333333;
            &:focus {
                outline: none !important;
            }
        }
    }
}