/* 
    Images path
    ========================================================================== */

@function img-url($string) {
    @return url('#{$img-path}#{$string}');
}


/* 
   Pixels to rem
   ========================================================================== */

@function rem($size) {
    @return $size / 16px * 1rem;
}


/*
    SVG
    ========================================================================== */

@function cstr-replace($string, $search, $replace: '') {

    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + cstr-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }   
    @return $string;
}

$encoding-reference: (
    ('<','%3C'),
    ('>','%3E'),
    ('"','\''),
    ('#','%23'),
    ('&','%26'),
);

@function svg-encode($svg) {
    
    @each $char, $encoded in $encoding-reference {
        $svg: cstr-replace($svg, $char, $encoded);
    }
    @return 'data:image/svg+xml,' + $svg;
}
