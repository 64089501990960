.actions-section-previouwing {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0;
    border-bottom: 1px solid #0000001f;
    padding-bottom: 20px;
    .label-status {
        background-color: #1abcb0;
        padding: 5px 27px;
        font-size: 14px;
        color: white;
        font-weight: 600;
        border-radius: 15px;
    }
    .actions {
        margin-left: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        &>* {
            margin-left: 7px;
        }
        .bill-views {
            display: flex;
            margin: 0 20px;
            .view {
                display: flex;
                align-items: center;
                margin: 0 5px;
                .PrivateSwitchBase-root-15 {
                    padding: 2px;
                }
                .label {
                    font-weight: normal;
                }
            }
        }
        .preview-button {
            background-color: rgba(128, 128, 128, 0.13);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 38px;
            .MuiButton-label {
                color: black;
            }
            &:hover {
                background-color: rgba(128, 128, 128, 0.3);
            }
        }
        .send-bill {
            .MuiButton-label {
                display: flex;
                justify-content: center;
                .MuiCircularProgress-root {
                    color: white;
                    width: 30px !important;
                    height: 30px !important;
                }
            }
        }
    }
    .pdf{
        .MuiButton-label{
            width: 22px;
        }
        .MuiCircularProgress-root{
            height: auto !important;

        }
    }
    .MuiIconButton-colorSecondary, .MuiRadio-colorSecondary.Mui-checked{
        color: unset;
    }
}