.form-section-amount-transfer {
    .form-section {
        margin-bottom: 0;
    }
    .recap-form-amount {
        .field-form {
            margin-top: 25px;
        }
    }
    .response,
    .options {
        .form-section .content-form-section {
            flex-direction: column;
        }
        .field_price_section {
            display: flex;
            justify-content: space-between;
            width: 70%;
            margin-bottom: 30px;
            .field_price {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }
    .card_options {
        width: 100%;
    }
    .options_content {
        display: flex;
        align-items: center;
    }
    .buttons-content {
        padding-top: 50px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-right: 30px;
        .MuiButton-containedPrimary {
            margin-left: 30px;
        }
    }
}
