.form-part-align-center {
    .field-form {
        display: flex;
        flex-direction: row;
        align-items: center;
      
        .decorator-input {
           padding-bottom: 30px;
            padding-top: 0;
        }
    }
}
