.MuiButton-contained {
    font-size: rem(12px) $imp;
    min-height: 38px;
    padding-left: 20px $imp;
    padding-right: 20px $imp;
    box-shadow: none $imp;
    &.btn-rounded {
        border-radius: 30px;
    }
    &.MuiButton-containedSizeLarge {
        min-height: 56px;
        font-size: rem(15px);
    }
    &.btn-form {
        min-width: 234px;
    }
    &.btn-icon {
        padding-left: 15px $imp;
        padding-right: 15px $imp;
        justify-content: space-between;
        .MuiIcon-root {
            font-size: 1.46rem;
        }
    }
    &.download {
        background-color: #25c2fe;
        &.MuiButton-label {
            span {
                color: white;
            }
        }
        &:hover {
            background-color: #00abee !important;
        }
    }
}

.btn-shadow {
    box-shadow: 0 3px 6px 0 rgba(#000, 0.16) $imp;
}