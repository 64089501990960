@import '../../../components/app-scss/base/variables';

.page-modal{
    display: flex;
    flex-direction: row;
    padding: 100px;

    .content-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45%;
        padding: 0px 50px;

        p{
            margin-top: 30px;
            min-width: 280px;
            margin-bottom: 30px;
            flex-grow: 1;
        }

        span{
            font-weight: bold;
            text-transform: uppercase;
        }

        .gabarit{
            display: flex;
            flex-direction: row;
            width: 240px;
            height: 60px;
            border-radius:3px;
            background-color: 'white';
            box-shadow: 0 3px 6px 0 rgba(#000, 0.16);
            
            .example-file{
                display: flex;
                flex-grow: 1;
                flex-direction: row;
                align-items: center;

                img{
                    margin: 0px 20px;
                    height: 40px;
                }

                .text-gabarit{
                    display: flex;
                    flex-direction: column;
    
                    .filename{
                        font-weight: normal;
                    }
                    .size{
                        color: $light-grey;
                        margin-top: 5px;
                        font-size: 12px;
                        font-weight: normal;
                    }
                }
            }

            .download-file{
                display: flex;
                justify-content: center;
                align-items: center;
                width:60px;
                background-color: #A1E89B;
                cursor: pointer;

                img{
                    width: 20px;
                }
            } 
        }

        .drag-enter{
            opacity: 0.6;
        }

        .container-drag-and-drop{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            width: 250px;
            height: 200px;
            background-color: #A1E89B;

            img{
                width: 60px;
                margin-bottom: 20px;
            }

            span{
                color: white;
                text-align: center;
            }
        }

        .error-import{
            color: red;
            font-size: 12px;
            margin-top: 10px;
        }
    }

    .vertical-separator{
        margin: 0px 20px;
    }
}
