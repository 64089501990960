.infos_client_popup {
    width: 11%;
    height: 14%;
    background-color: white;
    z-index: 1;
    position: absolute;
    padding: 10px 45px 12px 12px;
    box-shadow: 4px 5px 13px rgba(71, 69, 69, 0.473);
    .section {
        display: flex;
        justify-content: space-between;
        .info_part {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 12px;
            &.last {
                padding-right: 16px;
            }
            .label {
                font-size: 14px;
                font-weight: normal;
                padding-bottom: 7px;
                letter-spacing: 1px;
            }
        }
    }
}

.no_infos_client_popup {
    background-color: white;
    padding: 10px;
    position: absolute;
    box-shadow: 4px 5px 13px rgba(71, 69, 69, 0.473);
}