.content-modal-step1 {
    padding-top: 50px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    min-height: 250px !important;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.label-important {
    margin: auto;
    margin-bottom: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 400px;
}

.sig-canvas {
    display: flex;
    margin: auto;
    margin-top: 20px;
}

.content-modal-admin {
    padding: 50px;
    .actions {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }
}
