.upload-image-table {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}
.table-scroll{
    height: 55vh;
}
.small-table-scroll{
    height: 45vh;
}
