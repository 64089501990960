.table-sub-table {
    .MuiTableContainer-root {
        
        overflow-x: unset !important;
    }
}
table {
    width: 100%;
    .subForm {
        color: white;
        border-left: 6px solid #fc9a07;
        border-right: 6px solid #fc9a07;
        background-color: #cecece40 !important;
    }
    .hide-sub-account {
        padding: 5px 10px;
        border-radius: 15px;
        background-color: rgb(148, 148, 148);
        color: white;
        cursor: pointer;
        &:hover {
            background-color: rgb(117, 117, 117);
        }
    }
}
