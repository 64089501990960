.actions-section {
    margin-top: 25px;
    display: flex;
    align-items: flex-end;
    .actions {
        span {
            font-size: 14px;
            font-weight: bold;
        }
    }
}