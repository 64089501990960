.content_main_separator {
    width: 100%;
    color: #2F2F2F;
    height: 1px;
    border: none;
    background-color: #2F2F2F;
}

.separator-margins {
    margin: 1.5rem 0
}