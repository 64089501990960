.line {
    padding: 15px 50px 15px 5px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    white-space: pre-line;
    .label {
        display: flex;
        .num {
            margin-right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                padding-left: 5px;
            }
        }
    }
    &.detail {
        font-size: 13px;
        padding: 0px 50px 15px 53px;
    }

    input{
        width: 80px;
        border: none;
        border-bottom: 1px solid #333333;
    }
}
