.header-modal .material-icons {
    position: absolute !important;
    top: 18px;
    right: 10px;
}

.header-history-conv {
    padding: 2em 2em 0 2em;
}

.header-separate {
    width: 80%;
    margin: 3em auto;
    border: 1px solid #707070;
}

.history-conv-ticket {
    margin: 0 2em 0.5em 2em;
    padding: 0 2em;
    border-left: 2px dashed #404040;
    position: relative;

    .box-history-conv-ticket {
        background-color: white;
        border: 1px solid #404040;
        border-radius: 3px;
        padding: 0.5em;
        position: absolute;
        left: -10px;
    }

    .content-reply-history-conv {
        margin-top: 0.5em;
    }

    .info-history-conv-email-date {
        font-weight: 500;
        font-size: 17px;
        span:first-child {
            margin-right: 1em;
        }
        span:nth-child(2) {
            float:right;
            margin-left: 1em;
        }
    }

    .reply-separate {
        margin: 1.5em 0;
        border: 1px solid #EFEFEF;
    }

}

.link-download{
    color: rgba(0, 0, 0, 0.87);
}