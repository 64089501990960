.config-provider-perimeters {
    padding: 5em 2.5em;
    margin: 0 auto;

    .provider-perimeter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;

        .delete-provider-perimeter {
            color: red;
            cursor: pointer;
        }
    }
}
