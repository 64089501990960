/* 
   General
   ========================================================================== */

.main-content {
    margin-top: 64px;
    padding: 2rem;
}
.row-md {
    margin-left: -7.5px;
    margin-right: -7.5px;

    > div {
        padding-left: 7.5px;
        padding-right: 7.5px;
    }
}
.section {
    margin-bottom: 1.5rem;
}


/* 
   Responsive
   ========================================================================== */

@media (min-width: $screen-xl) {

    .container {
        max-width: 1620px;
    }
}

@media (max-width: $screen-xl - 1) {

    .container {
        max-width: 100%;
    }
}
