.btn-form{
    img{
        width: 20px;
    }
}

.detail-ticket-reply {

    div span {
        font-weight: bold;
    }

    div:nth-child(-n+2) {
        display: inline-block;
        margin: 1em 0;
    }

    div:first-child {
        width: 75%;
    }

    div:nth-child(2){
        width: 25%;
    }

    div:nth-last-child(-n+2) {
        display: inline-block;
        width: 100%;
        margin: 1em 0;
    }
}
