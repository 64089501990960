@import './DialogExport.scss';

.content-modal-export {
    padding: 40px 30px;
    &-controls{
        min-width: 564px;
        padding: 10px 20px 0 20px;

        .provider-form-part {
            padding-left: 10px;
            margin-bottom: 5px;
        }
        .mgl4{
            margin-right: 2rem;
        }
        .MuiInputLabel-formControl, .label {
            transform: none;
            font-size: 0.8rem !important;
        }
        .MuiSelect-select {
            width: 140px;
        }
        .multi-select{
            width: 200px;
            box-shadow: none;
        }
        .radio-group{
            margin-top: 16px;
            .MuiFormControlLabel-label{
                font-size: 0.8rem;
            }
            .MuiRadio-root{
                padding: 4px;
            }
        }
        .MuiIconButton-colorSecondary, .MuiRadio-colorSecondary.Mui-checked{
            color: unset;
        }
    }


}




