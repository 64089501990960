.layout-section{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-bottom: 20px;
  
    .header-section{
        background-color: #2F2F2F;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 1rem;

        span{
            font-size: 2rem;
            color: #FFF;
        }
    }

    .header-actions{
        display: flex;
        flex-direction: row;
    }
  
    .content-section{
      display: flex;
      flex-direction: column;
      border: 1px solid #333433;
      padding: 10px;
    }
}