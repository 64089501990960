h1 {
    padding: 0 15px;
    .welcome{
        font-weight: normal;
    }
}

.links{
    padding: 20px 0;
    margin-bottom: 30px;
    .media-icon-box-col{
        cursor: pointer;
        .mib-body{
            color: #FFF;
            text-transform: uppercase;
        }
    }
}
