.form-section {
    .content-form-section {
        &.divers {
            .langue-section {
                display: flex;
                align-items: center;
                img {
                    width: 40px;
                    margin-top: 30px;
                    margin-left: 30px;
                }
            }
            .switch-container {
                margin-left: 7rem;
            }
            .pro-card-container {
                margin-left: 5rem;
            }
            .pro-card {
                display: flex;
                padding-top: 10px;
                justify-content: center;
            }
        }
    }
}

