.accordion_box {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.09);

    .header_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #333433;
        color: white;
        border-radius: 5px;
        padding: 10px 20px;

        .title{
            min-width: 200px;
        }
    }

    .header_box:not(:last-child) {
        border-radius: 5px 5px 0 0;
    }

    .content_box {
        display: flex;
        padding: 10px 20px;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
    }
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.search-row {
    justify-content: flex-end;
}
