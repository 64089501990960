.container-decline-quotation {
    padding: 50px;
    display: flex;
    flex-direction: column;

    fieldset:not(:first-child) {
        margin-top: 20px;
    }

    textarea {
        min-width: 550px;
        margin-top: 10px;
    }

    .actions-declined {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }
}
