.provider-vehicle-form {
    .esthetics-vehicle {
        .MuiFormControl-root {
            margin-right: 40px;
        }
    }
    .with-previous {
        flex-direction: row;
        align-items: flex-end;
        .preview_image {
            margin-left: 25px;
        }
    }
    .MuiTypography-body1 {
        font-weight: 400;
        padding-top: 0;
        font-size: 12px;
    }
    .MuiCheckbox-root {
        padding: 5px;
    }
    .options-vehicle {
        .MuiFormControlLabel-root {
            width: 145px;
        }
        .label-options-vehicle {
            font-size: 11px !important;
            font-weight: bold !important;
            margin-bottom: 13px;
        }
    }
}