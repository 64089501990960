.mgl2 {
    margin-left: 2rem;
}

.mgr1 {
    margin-right: 1rem;
}

.mgr2 {
    margin-right: 2rem;
}

.mgt4 {
    margin-top: 4rem;
}

.mgt2 {
    margin-top: 2rem;
}

.mgt1 {
    margin-top: 1rem;
}

.pdt2 {
    padding-top: 2px;
}

.pdt6 {
    padding-top: 6px;
}

.mgb2 {
    margin-bottom: 2rem;
}

.mgb4 {
    margin-bottom: 4rem;
}

.pl-10 {
    padding-left: 10px $imp;
}

.no-gutters-left-col {
    padding-left: 0;
}

.MuiFormLabel-root {
    color: $body-color $imp;
    font-weight: 700 $imp;
    font-size: rem(16px) $imp;
}

.label.MuiFormLabel-root {
    margin-bottom: 10px;
    font-size: 0.85rem $imp;
}

.MuiInputBase-root {
    font-size: rem(16px) $imp;
    &:before,
    &:after,
    &:hover {
        border-bottom: none $imp;
    }
}

form {

    .MuiInputBase-input {
        //text-indent: 10px;
        //margin-top: 8px;
    }
}

.MuiInput-input,
.MuiInputBase-input {
    // text-indent: .75rem $imp;
    // border: 1px solid #cecece $imp;
    // border-radius: 4px $imp;

}

.MuiInput-formControl {

    .MuiInput-input {
        color: #000 $imp;
        padding-left: 10px $imp;

        &::placeholder {
            color: #000 $imp;
            opacity: 1;
        }
    }
}

%shadow-form-control {
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.shadow-form-control {
    @extend %shadow-form-control;

    .MuiInputLabel-formControl {
        font-weight: 400 $imp;
        padding-left: 10px;
    }
    .MuiInput-input {
        font-weight: 400;
    }
}
.shadow-form-control-date {
    min-width: 170px $imp;

    input {
        @extend %shadow-form-control;
    }
}

.field-form {
    margin-bottom: 1rem;

    .bold-label {
        font-weight: 900;
    }
}
span.MuiFormControlLabel-label,
.bold-label {
    font-weight: 900 $imp;
}

.form-group {
    margin-bottom: 1rem;

    > div {
        width: 100%;
    }

    &.decorator {
        display: flex;
        align-items: center;
    }
    .decorator-input {
        width: auto;

        > span {
            margin-left: .2rem;
        }
    }
}

//== Filter standard row (global filters tables)
.filter-std-row {

    > div {

        + div {
            margin-left: .75rem;
        }
    }
    .dropdown-heading {
        height: calc(var(--rmsc-h) + 2px);
    }
    .filter {

        .MuiFormControl-root {
            margin-left: 0;
            margin-right: 0;
        }
    }
}


.MuiList-root {
    padding-top: 0 $imp;
    padding-bottom: 0 $imp;
    .MuiMenuItem-root {
        font-size: 0.85rem;
    }
}

.label-form {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 0.8rem;
}

.switch-label-top {
    &.MuiFormControlLabel-labelPlacementTop {
        align-items: flex-start !important;
        .MuiTypography-root {
            margin-bottom: 10px;
            align-self: flex-start;
            font-weight: 700;
            font-size: 0.75rem !important;
        }
    }
}
.section-image-upload {
    margin-left: 100px;
    label {
        font-weight: 700;
    }
}
.user-image-upload {

    > .MuiFormLabel-root {
        font-size: 0.8rem $imp;
    }
    .MuiIconButton-root {
        background: none $imp;
        padding-top: 0;

        &:hover,
        &:focus {

            .MuiIconButton-label {

                > span {
                    background: #f1f1f1;
                }
            }
        }
    }

    .MuiIconButton-label {
        position: relative;
        // @include size(110px);
        border-radius: 50%;
        box-shadow: 0 3px 6px 0 rgba(#000, 0.16);

        > span {
            position: absolute;
            // right: -15px;
            // bottom: 10px;
            // @include size(46px);
            background-color: #fff;
            // line-height: 46px;
            text-align: center;
            border-radius: 50%;
            box-shadow: 0 3px 6px 0 rgba(#000, 0.16);
            transition: all ease 0.4s;
        }
    }
}

.switch-ios {
    font-size: 0.75rem !important;
    &.MuiSwitch-root {
        width: 58px;
        height: 26px;
        padding: 0;
    }
    .MuiSwitch-switchBase {
        &.Mui-checked {
            transform: translateX(31px);
            color: #fff;
            + .MuiSwitch-track {
                background-color: #a2efa2;
                opacity: 1;
            }
        }
    }
    .MuiSwitch-switchBase {
        padding: 1px;
        transform: translateX(1px);
        color: #fff;
    }
    .MuiSwitch-thumb {
        @include size(24px);
        box-shadow: none;
    }
    .MuiSwitch-track {
        height: 26px;
        background-color: #f36666;
        opacity: 1;
        border-radius: 16px;
    }
}

.checkbox {
    .MuiSvgIcon-root {
        fill: #707070;

    }
    ~ .MuiFormControlLabel-label {
        font-size: 0.75rem $imp;
    }
    &.MuiCheckbox-root {
    }
    &.Mui-checked {
        .MuiSvgIcon-root {
           // fill: #e8b01b;

        }
    }
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #e8b01b !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: rgb(255, 242, 211) !important;
}

.textarea {
    padding: 10px 8px;
    border-color: #dbdbdb $imp;
    font-family: $ff-rob $imp;
    font-size: 1rem;
    border-radius: 6px;
}

.MuiAutocomplete-popper {

    .spacer {
        margin: 0 20px 0 10px;
    }
}

.has-phone-input-as-input-height {

    .react-tel-input {
        margin-top: 0 $imp;

        input {
            height: 56px;
        }
    }
}

.simples-input-display-column-group {
    display: flex;
    min-height: 56px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}
.simple-input-display {
    height: 56px;
    display: inline-flex;
    align-items: center;
    white-space: pre-wrap;

    > strong,
    > span {
        line-height: 1.2;
    }

    .simples-input-display-column-group & {
        height: auto;

        + .simple-input-display {
            margin-top: .5rem;
        }
    }
}
