.models-brand-filter {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
    padding-bottom: 2px;
    background-color: transparent;
    border-bottom: 2px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    min-width: 150px;
}

.models-brand-filter:focus {
    outline: none;
}
