.reset-password-page {
    .container-login {
        padding: 27px 0;
        .login {
            height: 75%;
        }
        .logo-spark {
            width: 88%;
            margin-bottom: 30px;
        }
        .title {
            text-align: center;
        }
    }
}
